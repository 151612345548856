<template>
    <v-dialog
        persistent
        v-model="cloneDialog"
        transition="dialog-bottom-transition"
        overlay=false
        scrollable
        width="700px"
        min-width="600px"
        max-width="800px"
    >
        <v-card>
            <v-card-title class="success white--text">{{ $t('message.cloneThisWareHousePurchaseOrderToDirectSales') }}</v-card-title>
            <v-card-text>
                <v-form lazy-validation ref="cloneContractForm" v-model="validForm">
                    <v-layout row pt-3>
                        <v-flex xs5 lg5 md6 pl-5 py-1 class="align-center">{{ $t('message.originalWarehousePo') }}</v-flex>
                        <v-flex xs7 lg7 md6 py-1>
                            <v-text-field
                                :value="OriginalContract__title"
                                class="force-text-left"
                                dense
                                hide-details="auto"
                                readonly
                                solo
                            ></v-text-field>
                        </v-flex>
                    </v-layout>
                    <v-layout row v-if="Contract__Purchaseorder__id != null && false">
                        <v-flex xs5 lg5 md6 pl-5 py-1 class="align-center">{{ $t('message.copyContract') }}?</v-flex>
                        <v-flex xs7 lg7 md6 py-1>
                            <v-checkbox
                                :true-value="1"
                                autocomplete="off"
                                class="mt-0"
                                dense
                                hide-details="auto"
                                readonly
                                solo
                                v-if="Contract__salestype_id != 2"
                                v-model="copyPurchaseOrder"
                            ></v-checkbox>
                        </v-flex>
                    </v-layout>
                    <v-layout row v-if="Contract__Purchaseorder__id == null && Contract__salestype_id != 2" class="pt-3">
                        <v-flex xs5 lg5 md6 pl-5 py-1 class="align-center">{{ $t('message.copyPurchaseOrder') }}?</v-flex>
                        <v-flex xs7 lg7 md6><span class="red--text">{{ $t('message.noPurchaseOrderAvailable') }}</span></v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs5 lg5 md6 pl-5 py-1 class="align-center">{{ $t('message.newContractNumber') }}</v-flex>
                        <v-flex xs7 lg7 md6 py-1>
                            <v-text-field
                                :value="Contract__title"
                                :background-color="[1,3,5].includes(Contract__salestype_id) ? 'grey lighten-3' : ''"
                                :label="Contract__title ? '' : $t('message.required')"
                                :rules="[rules.number]"
                                class="force-text-left"
                                clearable
                                dense
                                hide-details="auto"
                                required
                                solo
                                @change="Contract__title = $event"
                            >
                                <template v-slot:append>
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                            <v-icon
                                                    class="orange--text text--darken-3"
                                                    v-on="on"
                                                    @click="getNewContractNumber"
                                            >mdi-download</v-icon>
                                        </template>
                                        <span>{{ $t('message.getNewContractNumber') }}</span>
                                    </v-tooltip>
                                </template>
                            </v-text-field>
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs5 lg5 md6 pl-5 py-1 class="align-center">{{ $t('message.company') }}</v-flex>
                        <v-flex xs7 lg7 md6 py-1>
                            <v-select
                                :items="filteredOffices"
                                :label="Contract__office_id ? '' : $t('message.required')"
                                :rules="[rules.office]"
                                clearable
                                dense
                                hide-details="auto"
                                item-text="Office.title"
                                item-value="Office.id"
                                required
                                solo
                                v-model="Contract__office_id"
                            ></v-select>
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs5 lg5 md6 pl-5 py-1 class="align-center">{{ $t('message.salesColleague') }}</v-flex>
                        <v-flex xs7 lg7 md6 py-1>
                            <v-autocomplete
                                :items="allSalesColleagues"
                                :label="Contract__salescontact_id ? '' : $t('message.required')"
                                :rules="[rules.salesColleague]"
                                clearable
                                dense
                                hide-details="auto"
                                item-text="Salesperson.name"
                                item-value="Salesperson.id"
                                required
                                solo
                                v-model="Contract__salescontact_id"
                                @change="salesColleagueChanged()"
                            >
                                <template v-slot:item="data">
                                    <template>
                                        <v-list-item-avatar>
                                            <img :src="data.item.User.avatar ? data.item.User.avatar : '/static/avatars/appic_user.jpg'">
                                        </v-list-item-avatar>
                                        <v-list-item-content>
                                            <v-list-item-title v-html="data.item.Salesperson.name"></v-list-item-title>
                                            <v-list-item-subtitle v-html="data.item.Country.name"></v-list-item-subtitle>
                                        </v-list-item-content>
                                    </template>
                                </template>
                            </v-autocomplete>
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs5 lg5 md6 pl-5 py-1 class="align-center">{{ $t('message.type') }}</v-flex>
                        <v-flex xs7 lg7 md6 py-1>
                            <v-select
                                :items="filteredSalestypes"
                                :label="Contract__salestype_id ? '' : $t('message.required')"
                                :rules="[rules.salesType]"
                                clearable
                                dense
                                hide-details="auto"
                                item-text="Salestype.title"
                                item-value="Salestype.id"
                                solo
                                v-model="Contract__salestype_id"
                            >
                            </v-select>
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs5 lg5 md6 pl-5 py-1 class="align-center">{{ $t('message.buyer') }}</v-flex>
                        <v-flex xs7 lg7 md6 py-1>
                            <v-autocomplete
                                :disabled="Contract__salescontact_id == null"
                                :items=filteredBuyers
                                :label="Contract__customer_id ? '' : (Contract__salescontact_id == null ? $t('message.selectSalesColleagueFirst') : $t('message.required'))"
                                :loading="loading.buyers"
                                :rules="[rules.buyer]"
                                clearable
                                dense
                                hide-details="auto"
                                item-text="Customer.title"
                                item-value="Customer.id"
                                required
                                solo
                                v-model="Contract__customer_id"
                            ></v-autocomplete>
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs5 lg5 md6 pl-5 py-1 class="align-center">{{ $t('message.date') }}</v-flex>
                        <v-flex xs7 lg7 md6 py-1>
                            <v-menu
                                ref="contractDateMenu"
                                v-model="contractDateMenu"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                max-width="290px"
                                min-width="290px"
                            >
                                <template v-slot:activator="{ on }">
                                    <v-text-field
                                        :label="Contract__contractdate ? '' : $t('message.required')"
                                        :rules="[rules.date]"
                                        :value="computedContractDateFormatted"
                                        append-icon="event"
                                        background-color="white"
                                        class="force-text-left"
                                        clearable
                                        hide-details="auto"
                                        hint="DD/MM/YYYY format"
                                        persistent-hint
                                        readonly
                                        solo
                                        v-on="on"
                                        @click:clear="Contract__contractdate = null"
                                        @click:append="contractDateMenu = true"
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                    v-model="Contract__contractdate"
                                    first-day-of-week="1"
                                    locale-first-day-of-year="4"
                                    show-week
                                    @input="contractDateMenu = false"
                                ></v-date-picker>
                            </v-menu>
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs5 lg5 md6 pl-5 py-1 class="align-center">{{ $t('message.supplier') }}</v-flex>
                        <v-flex xs7 lg7 md6 py-1>
                            <v-autocomplete
                                :items=allSuppliers
                                :label="Contract__supplier_id ? '' : $t('message.required')"
                                clearable
                                dense
                                hide-details="auto"
                                item-text="Supplier.title"
                                item-value="Supplier.id"
                                required
                                solo
                                v-model="Contract__supplier_id"
                                @change="supplierChanged"
                            ></v-autocomplete>
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs5 lg5 md6 pl-5 py-1 class="align-center">{{ $t('message.shippingWeek') }}</v-flex>
                        <v-flex xs7 lg7 md6 py-1>
                            <v-combobox
                                :items="sortedCalendarWeeks"
                                :label="Contract__shippingweekstatement ? '' : $t('message.required')"
                                :rules="[rules.date]"
                                :return-object="false"
                                clearable
                                dense
                                hide-details="auto"
                                item-value="Calendarweek.title"
                                item-text="Calendarweek.title"
                                solo
                                v-model="Contract__shippingweekstatement"
                            >
                                <template v-slot:item="{item}">
                                    <template v-if="item.Calendarweek.id === getCurrentWeek()">
                                        <div class="d-flex flex-column" style="width: 100% !important;">
                                            <div class="font-weight-bold my-3">{{ getCurrentYear() }}</div>
                                            <div>{{ item.Calendarweek.title + ': ' + item.Calendarweek.dateRange }}</div>
                                        </div>
                                    </template>
                                    <template v-if="item.Calendarweek.id > getCurrentWeek()">
                                        <template v-if="[52,53].includes(item.Calendarweek.id)">
                                            <div class="d-flex flex-column" style="width: 100% !important;">
                                                <div>{{ item.Calendarweek.title + ': ' + item.Calendarweek.dateRange }}</div>
                                                <v-divider></v-divider>
                                                <div class="font-weight-bold mb-3">{{ getNextYear() }}</div>
                                            </div>
                                        </template>
                                        <template v-else>
                                            <span>{{ item.Calendarweek.title + ': ' + item.Calendarweek.dateRange }}</span>
                                        </template>
                                    </template>
                                    <template v-if="item.Calendarweek.id < getCurrentWeek()">
                                        <span>{{ item.Calendarweek.title + ': ' + item.Calendarweek.dateRange }}</span>
                                    </template>
                                </template>
                            </v-combobox>
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs5 lg5 md6 pl-5 py-1 class="align-center">{{ $t('message.osd') }}</v-flex>
                        <v-flex xs7 lg7 md6 py-1>
                            <v-menu
                                ref="originalShippingDateMenu"
                                v-model="originalShippingDateMenu"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                max-width="290px"
                                min-width="290px"
                            >
                                <template v-slot:activator="{ on }">
                                    <v-text-field
                                        :label="Contract__shipweekdate ? '' : $t('message.required')"
                                        :rules="[rules.osd]"
                                        :value="computedOriginalShippingDateFormatted"
                                        append-icon="event"
                                        background-color="white"
                                        class="force-text-left"
                                        clearable
                                        hide-details="auto"
                                        hint="DD/MM/YYYY format"
                                        persistent-hint
                                        readonly
                                        solo
                                        v-on="on"
                                        @click:clear="Contract__shipweekdate = null"
                                        @click:append="originalShippingDateMenu = true"
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                    v-model="Contract__shipweekdate"
                                    first-day-of-week="1"
                                    locale-first-day-of-year="4"
                                    show-week
                                    @input="originalShippingDateMenu = false"
                                ></v-date-picker>
                            </v-menu>
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs6 lg5 md6 pl-5 py-1 class="align-center">{{ $t('message.incoterm') }}</v-flex>
                        <v-flex xs6 lg4 md6 py-1>
                            <v-select
                                :items=incoterms
                                :label="Contract__incoterm_id ? '' : $t('message.required')"
                                :rules="[rules.incoterm]"
                                clearable
                                dense
                                hide-details="auto"
                                item-value="Incoterm.id"
                                item-text="Incoterm.title"
                                required
                                solo
                                v-model="Contract__incoterm_id"
                            ></v-select>
                        </v-flex>
                        <v-flex xs4 lg3 md4 py-1 pl-0>
                            <v-select
                                :items=incotermports
                                :label="Contract__incotermport ? '' : $t('message.required')"
                                :rules="[rules.incotermPort]"
                                clearable
                                dense
                                hide-details="auto"
                                item-value="value"
                                item-text="title"
                                required
                                solo
                                v-model="Contract__incotermport"
                            >
                            </v-select>
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs6 lg5 md6 pl-5 py-1 class="align-center">{{ $t('message.numberOfContainers') }}</v-flex>
                        <v-flex xs7 lg7 md6 py-1>
                            <v-row class="px-3">
                                <v-col cols="5" class="pa-0 ma-0" v-if="[4,7].includes(Contract__containersize_id) == false">
                                    <v-text-field
                                        :value="Contract__containercount"
                                        autocomplete="off"
                                        class="force-text-left"
                                        clearable
                                        dense
                                        hide-details="auto"
                                        min="1"
                                        type="number"
                                        solo
                                        @change="Contract__containercount = $event"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col cols="1" class="pa-0 ma-0 text-center" v-if="[4,7].includes(Contract__containersize_id) == false">
                                    <v-icon class="mt-2" small>mdi-close</v-icon>
                                </v-col>
                                <v-col cols="6" class="pa-0 ma-0">
                                    <v-select
                                        :items="containerSizes"
                                        clearable
                                        dense
                                        hide-details="auto"
                                        item-text="Containersize.title"
                                        item-value="Containersize.id"
                                        reverse
                                        solo
                                        v-model="Contract__containersize_id"
                                    >
                                    </v-select>
                                </v-col>
                            </v-row>
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs5 lg5 md6 pl-5 py-1 class="align-center">{{ $t('message.portOfLoading') }}</v-flex>
                        <v-flex xs7 lg7 md6 py-1>
                            <v-autocomplete
                                    :items=allShippingLoadingPorts
                                    :label="Contract__loadingport_id ? '' : $t('message.required')"
                                    clearable
                                    dense
                                    item-text="Shippingport.title"
                                    item-value="Shippingport.id"
                                    hide-details="auto"
                                    required
                                    solo
                                    v-model="Contract__loadingport_id"
                            >
                                <template v-slot:selection="data">
                                    {{ data.item.Country.name ? data.item.Shippingport.title + '-' + data.item.Country.name : data.item.Shippingport.title }}
                                </template>
                                <template v-slot:item="data">
                                    {{ data.item.Country.name ? data.item.Shippingport.title + '-' + data.item.Country.name : data.item.Shippingport.title }}
                                </template>
                            </v-autocomplete>
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs5 lg5 md6 pl-5 py-1 class="align-center">{{ $t('message.portOfDischarge') }}</v-flex>
                        <v-flex xs7 lg7 md6 py-1>
                            <v-autocomplete
                                    :items=allShippingDischargePorts
                                    :label="Contract__shippingport_id ? '' : $t('message.required')"
                                    clearable
                                    dense
                                    item-text="Shippingport.title"
                                    item-value="Shippingport.id"
                                    hide-details="auto"
                                    solo
                                    required
                                    v-model="Contract__shippingport_id"
                            >
                                <template v-slot:selection="data">
                                    {{ data.item.Country.name ? data.item.Shippingport.title + ' - ' + data.item.Country.name : data.item.Shippingport.title }}
                                </template>
                                <template v-slot:item="data">
                                    {{ data.item.Country.name ? data.item.Shippingport.title + ' - ' + data.item.Country.name : data.item.Shippingport.title }}
                                </template>
                            </v-autocomplete>
                        </v-flex>
                    </v-layout>
                </v-form>
            </v-card-text>
            <v-card-actions class="pt-0 pr-8">
                <v-spacer></v-spacer>
                <v-btn color="default" small @click="cloneDialog = false" >{{$t('message.dismiss')}}</v-btn>
                <v-btn
                        :loading="loading.clone"
                        color="info"
                        small
                        @click="addClonedContract"
                >{{ $t('message.proceed') }}</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    // import Vue from 'vue'
    import {textTruncate, getCurrentAppLayout, formatDate} from "Helpers/helpers";
    import {mapActions, mapGetters} from "vuex";
    import {mapFields} from "vuex-map-fields";
    // import {mapFieldsPrefixed} from  'Helpers/vuex-map-fields-prefixed'
    import {
        getSafe,
        numberFormat,
        getCurrentWeek,
        getCurrentYear,
        getDateRangeOfWeek,
        getDateRangeOfWeekWithYear,
        getNextYear,
        yearHasExtraWeek
    } from "Helpers/helpers";
    import router from "../../router";
    import {api} from "../../api";

    export default {
        name: "ClonePurchaseOrder",
        props: ['dialog','dialog-closed'],
        data(){
            return {
                clone_dialog: false,
                contractDateMenu: false,
                dialogs: {
                    error:  false,
                    error_message: ""
                },
                errors: {
                    missing_values: []
                },
                filteredBuyers: [],
                filteredSalestypes: [
                    {Salestype: {id: 1, title: 'Direct Sales', code: 'DIR'}},
                    {Salestype: {id: 5, title: 'Serviced Sales', code: 'SS'}},
                ],
                filters: {
                    offices: {
                        salestype_id: null,
                        country_id: null,
                    },
                    banks: {
                        office_id: null,
                        currency_id: null
                    }
                },
                incotermports: [
                    {value: 0, title: 'POD'},
                    {value: 1, title: 'POL'},
                    {value: 2, title: this.$t('message.doNotShow')},
                ],
                loading: {
                    buyers: false,
                    clone: false,
                    newContractNumber: false,
                },
                originalShippingDateFormatted: null,
                originalShippingDateMenu: false,
                rules: {
                    buyer: v => !!v || this.$t('message.required'),
                    date: v => !!v || this.$t('message.required'),
                    incoterm: v => !!v || this.$t('message.required'),
                    incotermPort: v => typeof v === "number" || this.$t('message.required'),
                    number: v => !!v || this.$t('message.required'),
                    office: v => !!v || this.$t('message.required'),
                    osd: v => !!v || this.$t('message.required'),
                    salesColleague: v => !!v || this.$t('message.required'),
                    salesType: v => !!v || this.$t('message.required')
                },
                validForm: true
            }
        },
        computed: {
            ...mapFields('purchaseorder',{
                Contract__id: 'clone.Contract.id',
                Contract__bank_id: 'clone.Contract.bank_id',
                Contract__buyeraddresstype: 'Contract.buyeraddresstype',
                Contract__currency_id: 'clone.Contract.currency_id',
                Contract__commission_term_id: 'clone.Contract.commission_term_id',
                Contract__commission_term_against_id:'clone.Contract.commission_term_against_id',
                Contract__contractdate: 'clone.Contract.contractdate',
                Contract__containercount: 'clone.Contract.containercount',
                Contract__containersize_id: 'clone.Contract.containersize_id',
                Contract__customer_id: 'clone.Contract.customer_id',
                Contract__customer_pi_no: 'clone.Contract.customer_pi_no',
                Contract__date_signed: 'clone.Contract.date_signed',
                Contract__deliveryaddress_id: 'clone.Contract.deliveryaddress_id',
                Contract__destinatione_id: 'clone.Contract.destinatione_id',
                Contract__footer_notes: 'clone.Contract.footer_notes',
                Contract__fsc: 'clone.Contract.fsc',
                Contract__is_export: 'vContract.is_export',
                Contract__issigned: 'clone.Contract.issigned',
                Contract__isposigned: 'clone.Contract.isposigned',
                Contract__incoterm_id: 'clone.Contract.incoterm_id',
                Contract__incotermport: 'clone.Contract.incotermport',
                Contract__loadingport_id: 'clone.Contract.loadingport_id',
                Contract__marketsegment_id: 'clone.Contract.marketsegment_id',
                Contract__office_id: 'clone.Contract.office_id',
                Contract__partial_no: 'clone.Contract.partial_no',
                Contract__paymentstatement_id: 'clone.Contract.paymentstatement_id',
                Contract__paymentterm_id: 'clone.Contract.paymentterm_id',
                Contract__productgroup_id: 'clone.Contract.productgroup_id',
                Contract__productorigin_id: 'clone.Contract.productorigin_id',
                Contract__po_date_signed: 'clone.Contract.purchaseorder.date_signed',
                Contract__revision_no: 'clone.Contract.revision_no',
                Contract__salescontact_id: 'clone.Contract.salescontact_id',
                Contract__salestype_id: 'clone.Contract.salestype_id',
                Contract__sellertitle: 'clone.Contract.sellertitle',
                Contract__shippingport_id: 'clone.Contract.shippingport_id',
                Contract__shipweekdate: 'clone.Contract.shipweekdate',
                Contract__shippingweekstatement: 'clone.Contract.shippingweekstatement',
                Contract__showbank: 'clone.Contract.showbank',
                Contract__showsignature: 'clone.Contract.showsignature',
                Contract__stampsignatured: 'clone.Contract.stampsignatured',
                Contract__supplier_id: 'clone.Contract.supplier_id',
                Contract__supplier_pi_no: 'clone.Contract.supplier_pi_no',
                Contract__title: 'clone.Contract.title',
                Contract__Purchaseorder__id: 'update.Purchaseorder.id',
                OriginalContract__id: 'clone.OriginalContract.id',
                OriginalContract__title: 'clone.OriginalContract.title',
                copyPurchaseOrder: 'clone.copyPurchaseOrder'
            }),
            ...mapGetters('buyer', {
                allBuyers: 'allBuyers',
                findBuyerById: 'findBuyerById'
            }),
            ...mapGetters('salescolleague', {
                allSalesColleagues: 'allSalesColleagues'
            }),
            ...mapGetters('shippingport', {
                allShippingLoadingPorts: 'allShippingLoadingPorts',
                allShippingDischargePorts: 'allShippingDischargePorts'
            }),
            ...mapGetters('supplier', {
                allSuppliers: 'allSuppliers',
                findSupplierById: 'findSupplierById'
            }),
            ...mapGetters([
                'buyerContractOffices',
                // 'buyerCaptionTypes',
                'calendarWeeks',
                'certifications',
                'containerSizes',
                // 'contractFooterNotes',
                // 'deliveryAddressTypes',
                'incoterms',
                'offices',
                'officeCountries',
                // 'salesTypes',
                // 'statuses',
                // 'productGroups'
            ]),
            cloneDialog: {
                get() {
                    return this.clone_dialog;
                },
                set(value){
                    this.clone_dialog = value
                    if(value == false) {
                        this.$emit('dialog-closed')
                    }
                }
            },
            computedContractDateFormatted () {
                return formatDate(this.Contract__contractdate)
            },
            computedOriginalShippingDateFormatted () {
                return formatDate(this.Contract__shipweekdate)
            },
            filteredOffices() {
                return this.offices.filter(i => {
                    return [1,20,21,22].includes(i.Office.id) == true
                })
            },
            partialContractNumbers: function () {
                let items = []
                items.push({'text': this.$t('message.no'), 'value': null})
                let i
                for (i = 1; i <= 10; i++) {
                    let obj = {}
                    obj['text'] = '- ' + i
                    obj['value'] = i.toString()
                    items.push(obj)
                }
                return items
            },
            revisionNumbers: function () {
                let items = []
                items.push({'text': this.$t('message.none'), 'value': null})
                let i
                for (i = 1; i <= 10; i++) {
                    let obj = {}
                    obj['text'] = 'Rev' + i.toString()
                    obj['value'] = 'Rev' + i.toString()
                    items.push(obj)
                }
                return items
            },
            sortedCalendarWeeks: function () {
                const currentWeek = getCurrentWeek();
                let nextWeeks = []
                let previousWeeks = []
                this.calendarWeeks.forEach(cw => {
                    if(cw.Calendarweek.id >= currentWeek){
                        nextWeeks.push(cw)
                    } else {
                        previousWeeks.push(cw)
                    }
                })

                //check if current year has week 53
                if(yearHasExtraWeek(getCurrentYear)){
                    const calendarObj = {Calendarweek: {id:53, title: 'WK 53', dateRange: ''}}
                    nextWeeks.push(calendarObj)
                }

                //generate date range values
                nextWeeks.forEach((item, idx) => {
                    nextWeeks[idx]['Calendarweek']['dateRange'] = getDateRangeOfWeek(item.Calendarweek.id)
                })
                previousWeeks.forEach((item, idx) => {
                    previousWeeks[idx]['Calendarweek']['dateRange'] = getDateRangeOfWeekWithYear(item.Calendarweek.id, getNextYear())
                })
                const weeks = [...nextWeeks, ...previousWeeks]
                return weeks
            }
        },
        methods: {
            ...mapActions('buyer',{
                getAllActiveBuyers: 'getAllActiveBuyers'
            }),
            ...mapActions('contract', {
                contractBuyerChanged: 'contractBuyerChanged',
                resetAllContracts: 'resetAllContracts',
            }),
            ...mapActions('purchaseorder',{
                createClonedContract: 'createClonedContract'
            }),
            ...mapActions('salescolleague', {
                getAllSalesColleagues: 'getAllSalesColleagues',
            }),
            addClonedContract(){
                if(this.$refs.cloneContractForm.validate()) {
                    this.loading.clone = true
                    this.createClonedContract()
                        .then((response) => {
                            if (response.status == 'success') {
                                this.$toast.success(this.$t('message.successes.contractCloned'),
                                    {
                                        classes: ['icon-float-left'],
                                        icon: 'check_circle_outline'
                                    }
                                )
                                this.loading.clone = false
                                this.resetAllContracts().then(() => {
                                    // push new route
                                    router.push({
                                        name: 'update_contract',
                                        params: {contractId: response.contract_id}
                                    }).catch(err => {
                                    })
                                })
                            } else {
                                this.$toast.error(this.$t('message.errors.contractNotCloned'),
                                    {
                                        classes: ['icon-float-left'],
                                        icon: 'error_outline'
                                    }
                                )
                                this.loading.clone = false
                            }
                        })
                        .catch(() => {
                            this.$toast.error(this.$t('message.errors.contractNotCloned'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                            this.loading.clone = false
                        })
                        .finally(() => {
                            this.loading.clone = false
                        })
                } else {
                    this.$toast.error(this.$t('message.errors.contractNotCloned'),
                        {
                            classes: ['icon-float-left'],
                            icon: 'error_outline'
                        }
                    )
                }
            },
            getCurrentWeek,
            getCurrentYear,
            getNextYear,
            getDateRangeOfWeek,
            getDateRangeOfWeekWithYear,
            getNewContractNumber: function() {
                let contract_prefix = this.buyerContractOffices.find(d => d.salestype_id == this.filters.offices.salestype_id && d.country_id == this.filters.offices.country_id && d.office_id == this.Contract__office_id)?.contract_prefix
                if(this.Contract__customer_id != null && this.Contract__salestype_id != null && this.Contract__office_id != null && this.Contract__supplier_id != null) {
                    this.loading.newContractNumber = true
                    return new Promise ((resolve, reject) => {
                        api
                            .get("/contracts/number",{
                                params: {
                                    contract_prefix: contract_prefix,
                                    destinatione_id: this.Contract__destinatione_id,
                                    office_country_id: this.offices.find(office=>office.Office.country_id == this.Contract__office_id)?.Office?.country_id,
                                    productorigin_id: this.Contract__productorigin_id,
                                    salescontact_id: this.Contract__salescontact_id,
                                    salestype_id: this.Contract__salestype_id,
                                }
                            })
                            .then(response => {
                                if(response.data.status == 'success') {
                                    this.Contract__title = response.data.data
                                } else if(response.data.status == 'error'){
                                    // this.Contract__title = response.data.data
                                    this.enterContractNumberManually = true
                                }
                                this.loading.newContractNumber = false
                            })
                            .catch(error => {
                                reject(error);
                                this.$toast.error(this.$t('message.errors.noNewContractNo'),
                                    {
                                        classes: ['icon-float-left'],
                                        icon: 'error_outline'
                                    }
                                )
                                this.loading.newContractNumber = false
                            })
                            .finally(()=>{
                                this.loading.newContractNumber = false
                            })
                    })
                } else {
                    this.errors.missing_values = [] //empty errors first
                    if(this.Contract__customer_id == null){
                        this.errors.missing_values.push(this.$t('message.buyer'));
                    }
                    if(this.Contract__salestype_id == null){
                        this.errors.missing_values.push(this.$t('message.salesType'));
                    }
                    if(this.Contract__office_id == null){
                        this.errors.missing_values.push(this.$t('message.appTimberCompany'));
                    }
                    if(this.Contract__supplier_id == null){
                        this.errors.missing_values.push(this.$t('message.supplier'));
                    }
                    this.dialogs.error_message = this.$t('message.errors.cannotGetNewContractNumber');
                    this.dialogs.error = true;
                }
            },
            salesColleagueChanged() {
                this.loading.buyers = true
                this.filteredBuyers = []
                this.filteredBuyers = this.allBuyers.filter(b => b.Customer.salescontact_id == this.Contract__salescontact_id)
                this.loading.buyers = false
            },
            supplierChanged () {
                let supplier = this.findSupplierById(this.Contract__supplier_id)
                if(supplier != null){
                    this.Contract__productorigin_id = supplier.Country.id
                    //TODO deactivate for the meantime
                    // this.Contract__title = null
                }
            },
        },
        watch: {
            dialog(value) {
                this.clone_dialog = value
            }
        },
        created(){
            this.filters.offices.salestype_id = this.Contract__salestype_id
            if(this.Contract__customer_id) {
                let buyer = this.findBuyerById(this.Contract__customer_id)
                if (this.officeCountries.includes(buyer?.Country?.id)) {
                    this.filters.offices.country_id = buyer?.Country?.id
                } else {
                    this.filters.offices.country_id = 999
                }
            }
            if(this.allBuyers.length == 0) this.getAllActiveBuyers()
            if(this.allSalesColleagues.length == 0) this.getAllSalesColleagues()
        }
    }
</script>

<style scoped>

</style>